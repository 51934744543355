import React from "react";
import ReactDOM from "react-dom";
import { PrivyProvider } from "@privy-io/react-auth";
import App from "./App";

const PRIVY_APP_ID = "cm5usya7z01nhxmo24vhbth3z"; // Replace with your actual Privy App ID

ReactDOM.render(
  <React.StrictMode>
    <PrivyProvider appId={PRIVY_APP_ID} scopes={["wallets"]}>
      <App />
    </PrivyProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
