import React, { useState } from "react";
import "./App.css";
import { usePrivy } from "@privy-io/react-auth";

function App() {
    const { login, logout, authenticated, user } = usePrivy();
    const [password, setPassword] = useState(""); // Store the password
    const [imageURL, setImageURL] = useState(""); // Store the minted NFT image URL
    const [loading, setLoading] = useState(false); // Loading state
    const [message, setMessage] = useState(""); // Feedback message for users
    const [tokenURL, setTokenURL] = useState(""); // Transaction link
    const [nftName, setNftName] = useState(""); // NFT name

    const handleMint = async () => {
        if (!password) {
            setMessage("Please enter a password.");
            return;
        }

        if (!authenticated) {
            console.log("User not authenticated.");
            setMessage("Please connect your wallet first.");
            return;
        }

        const walletAddress = user?.wallet?.address;
        if (!walletAddress) {
            setMessage("No wallet address found. Please connect with Privy.");
            return;
        }

        setLoading(true);
        setMessage("");

        try {
            const response = await fetch("https://walletsponso-528476102501.europe-west1.run.app/mint", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    walletAddress: walletAddress,
                    password: password,
                }),
            });

            const data = await response.json();

            if (data.success) {
                setNftName(`Welcome DEX Ambassador!`);
                setImageURL(`${process.env.PUBLIC_URL}/nft_image.png`); // Static NFT image
                setTokenURL(`https://basescan.org/tx/${data.txHash}`); // Transaction link
            } else {
                setMessage(data.error || "Minting failed.");
            }
        } catch (error) {
            console.error("Error minting NFT:", error);
            setMessage("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const shareOnLinkedIn = () => {
        const text = [
            "I am happy and proud to announce that today I am joining Doors3 Executive Club as an ambassador, certified by this NFT badge!",
            "The Doors Executive Club is an exclusive circle dedicated to those who don’t just follow the wave - they create it. It’s a space where innovation meets excellence.",
            "We are looking for visionaries, builders, and trailblazers.",
            "👉 So if you want to join us send me a DM or follow this link https://ressources.doors3.io/doors-executive-club to register on the waiting list.",
            "Stay ahead, feel the future!",
        ].join("\n\n"); // Add two line breaks between paragraphs
    
        const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?text=${encodeURIComponent(text)}`;
        window.open(linkedInUrl, "_blank");
    };
    

    return (
        <div className="App">
            {/* Header Section */}
            <div className="header-container">
                <img
                    src={`${process.env.PUBLIC_URL}/Header.png`}
                    alt="DEX by Doors"
                    style={{ width: "100%" }}
                />
            </div>

            {/* Content Section with Background */}
            <div className="content-container">
                <img
                    src={`${process.env.PUBLIC_URL}/h1.png`}
                    alt="Claim Your Ambassador NFT"
                    style={{
                        maxWidth: "80%",
                        height: "auto",
                        marginBottom: "20px",
                        marginTop: "50px",
                    }}
                />
                {/* Blur Box */}
                <div className="blur-box">
                    {imageURL ? (
                        // Content to display after minting
                        <div>
                            <h2>{nftName}</h2>
                            <img
                                src={imageURL}
                                alt="Minted NFT"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "300px",
                                    marginTop: "20px",
                                }}
                            />
                            <p style={{ marginTop: "10px" }}>
                                Your NFT has been minted successfully!
                            </p>
                            <a
                                href={tokenURL}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: "#61dafb",
                                    textDecoration: "none",
                                    marginTop: "10px",
                                    display: "block",
                                }}
                            >
                                View Transaction on Explorer
                            </a>
                            <button
                                style={{
                                    marginTop: "10px",
                                    padding: "10px 20px",
                                    border: "none",
                                    borderRadius: "5px",
                                    background: "#0077b5",
                                    color: "white",
                                    cursor: "pointer",
                                }}
                                onClick={shareOnLinkedIn}
                            >
                                Share on LinkedIn
                            </button>
                        </div>
                    ) : (
                        // Content to display before minting
                        <>
                            <h2>Ready to mint your exclusive NFT?</h2>
                            <div className="spaced-content">
                                <p>
                                    Imagine a space where innovation meets excellence, where bold
                                    ideas come to life, and where the most forward-thinking leaders
                                    gather to shape the future. The Doors Executive Club is an
                                    exclusive circle dedicated to those who don’t just follow the
                                    wave - they create it.
                                </p>
                                <p>
                                    The ambassadors of this club are visionaries, builders, and
                                    trailblazers. People like you, who bring expertise, networks,
                                    and a passion for innovation.
                                </p>
                                <p>
                                    Your mission is simple: elevate the Club. Through your network,
                                    public engagements, and communication, you will become a
                                    cornerstone of its growth and impact.
                                </p>
                                <p>
                                    <strong>TOGETHER, LET’S MAKE THE CLUB MORE THAN A COMMUNITY</strong>
                                    <br />
                                    <strong>LET’S MAKE IT A DRIVING FORCE FOR TRANSFORMATION AND INNOVATION.</strong>
                                </p>
                                <p>
                                    This NFT badge certifies your membership in the Club as a VIP
                                    ambassador.
                                </p>
                            </div>
                            {!authenticated ? (
                                <button
                                    style={{
                                        marginTop: "20px",
                                        padding: "10px 20px",
                                        border: "none",
                                        borderRadius: "5px",
                                        background: "#f44336",
                                        color: "white",
                                        cursor: "pointer",
                                    }}
                                    onClick={login}
                                >
                                    Connect
                                </button>
                            ) : (
                                <>
                                    <p>
                                        Welcome,{" "}
                                        {user?.wallet?.address
                                            ? `${user.wallet.address.slice(0, 6)}...${user.wallet.address.slice(-4)}`
                                            : user?.email?.address || "User"}
                                        !
                                    </p>
                                    <input
                                        type="password"
                                        placeholder="Enter password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        style={{
                                            padding: "10px",
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            borderRadius: "5px",
                                            border: "1px solid #ccc",
                                        }}
                                    />
                                    <button
                                        style={{
                                            marginTop: "10px",
                                            padding: "10px 20px",
                                            border: "none",
                                            borderRadius: "5px",
                                            background: "#4CAF50",
                                            color: "white",
                                            cursor: "pointer",
                                        }}
                                        onClick={handleMint}
                                        disabled={loading}
                                    >
                                        {loading ? "Minting..." : "Mint NFT"}
                                    </button>
                                    <button
                                        style={{
                                            marginTop: "10px",
                                            padding: "10px 20px",
                                            border: "none",
                                            borderRadius: "5px",
                                            background: "black",
                                            color: "white",
                                            cursor: "pointer",
                                        }}
                                        onClick={logout}
                                    >
                                        Logout
                                    </button>
                                </>
                            )}
                            {message && <p style={{ marginTop: "10px", color: "red" }}>{message}</p>}
                        </>
                    )}
                </div>

                {/* External Links */}
                <div
                    className="image-links"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px",
                        alignItems: "center",
                        margin: "10px 0",
                    }}
                >
                    <a
                        href="https://doors3.io"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ margin: "0 10px" }}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/fram11.png`}
                            alt="Doors Lab Logo"
                            style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "8px",
                            }}
                        />
                    </a>
                    <a
                        href="https://opensea.io/assets/base/0x0b52f5A4E0ac8722BD0084A1BC3958020161573a
"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ margin: "0 50px" }}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/fram12.png`}
                            alt="See the Collection"
                            style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "8px",
                            }}
                        />
                    </a>
                    <a
                        href="https://linktr.ee/Doors3_Official"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ margin: "0 10px" }}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/fram13.png`}
                            alt="Dauphine Logo"
                            style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "8px",
                            }}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default App;
